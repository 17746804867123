import {Link} from "gatsby";
import React, {FC} from "react";
import Layout from "../components/layout/layout-default";

const NotFoundPage: FC = () => (
    <Layout>
        <section className="container mx-auto my-16 px-4 lg:px-0">
            <h1>{`404 - page not found :(`}</h1>
            <p>{`The content you're looking for could not be found.`}</p>
            <Link to="/">{`Back to start page`}</Link>
        </section>
    </Layout>
);

export default NotFoundPage;
